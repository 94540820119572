import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Alert from "../components/alert";

export default class SignUp extends Component {
  render() {
    return (
      <Grid container justify="center" className="view">
        <Grid item lg={4} md={6} xs={8}>
          <Alert type={"error"} message={<span>L'inscription est actuellement désactivée.<br/>Vous pouvez cependant vous connecter avec les identifiants de démo, proposés sur la page de Connexion</span>}/>
        </Grid>
      </Grid>
    );
  }
}
