import * as R from 'ramda';

const initialState = {
  currentPath: "/login",
};

const NavigationReducer = function (previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case 'CHANGE_TAB':
      return R.assoc('currentPath', action.path, newState);
    default:
      return previousState;
  }
};

export default NavigationReducer;
