import React, {Component} from 'react';
import UiTableSortLabel from "@material-ui/core/TableSortLabel";

class TableSortLabel extends Component {
  state = {};
  handleOnClick = () => {
    const orderBy = this.props.value;
    let order = 'desc';
    if (this.state.orderBy === this.props.value && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({orderBy, order});
    this.props.onClick(orderBy, order);
  };

  render() {
    const {order} = this.state;
    const {orderBy, value, label, direction, align} = this.props;
    const paddingLeft = align === "right" ? 10 : 0;
    return <UiTableSortLabel
      style={orderBy === value ? {
        fontWeight: "bold",
        backgroundColor: "#1769aa",
        color: "white",
        borderRadius: 5,
        padding: 10,
        paddingLeft,
      } : {}}
      className="sortLink"
      active={orderBy === value}
      direction={order || direction}
      onClick={this.handleOnClick}
    >
      {label}
    </UiTableSortLabel>;
  }
}

export default TableSortLabel;
