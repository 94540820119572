import React, {Component} from 'react';
import * as R from 'ramda';
import * as moment from 'moment';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {Close} from '@material-ui/icons';
import {bindActionCreators} from "redux";
import {
  deleteOperation,
  getOperations,
  getOperationsWithoutPagination,
  getCount,
  getSum,
  updateOperation,
  removeFromPredicate,
} from "../services/operation";
import {getDecoratorsWithoutPagination} from "../services/decorator";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import QuickStat from "./quickStat";

const FilterItem = (props) => {
  return props.isVisible ? (
    <Grid item>
      <Card style={{paddingLeft: 10, backgroundColor: '#1769aa', color: 'white'}}>
        {props.value}
        <IconButton aria-label="Remove filter" onClick={() => props.removeFromPredicate(props.fields || [props.field])}>
          <Close style={{fontSize: 17, color: 'white'}}/>
        </IconButton>
      </Card>
    </Grid>
  ) : null;
};

class PredicateBar extends Component {

  state = {
    filteredSum: 0,
    foreverSum: 0,
    foreverCount: 0,
  };

  componentDidMount() {
    this.props.getSum(this.props.predicate)
      .then((filteredSum) => {
        this.setState({filteredSum})
      });
    this.props.getSum({})
      .then((foreverSum) => {
        this.setState({foreverSum})
      });
    this.props.getCount({})
      .then((foreverCount) => {
        this.setState({foreverCount})
      });
  }

  componentDidUpdate(prevProps) {
    const {predicate} = this.props;
    if (!R.equals(prevProps.predicate, predicate)) {
      this.props.getSum(predicate)
        .then((filteredSum) => {
          this.setState({filteredSum})
        });
    }
  }

  render() {
    const {predicate, shops, categories} = this.props;
    const {amount, start, end, shop, category} = predicate;
    return (
      <Grid container justify="space-between">
        <Grid item>
          <Grid container spacing={8}>
            <FilterItem
              fields={["start", "end"]}
              isVisible={end && start}
              value={`Du ${moment(start).format("DD/MM/YYYY")} au ${moment(end).format("DD/MM/YYYY")}`}
              {...this.props}
            />
            <FilterItem
              field="category"
              isVisible={category}
              value={categories[category]}
              {...this.props}
            />
            <FilterItem
              field="shop"
              isVisible={shop}
              value={shops[shop]}
              {...this.props}
            />
            <FilterItem
              field="amount"
              isVisible={amount && amount !== ""}
              value={`${amount}€`}
              {...this.props}
            />
          </Grid>
        </Grid>
        <Grid item>
          <QuickStat/>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predicate: state.operation.predicate,
    categories: state.decorator.category.table,
    shops: state.decorator.shop.table,
    filteredCount: state.operation.meta.count,
    sum: R.reduce(R.add, 0, R.map(R.prop('amount'), state.operation.notPaginatedList)).toFixed(2),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteOperation,
  getDecoratorsWithoutPagination,
  getOperationsWithoutPagination,
  getOperations,
  getCount,
  getSum,
  updateOperation,
  removeFromPredicate,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PredicateBar));
