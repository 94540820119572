import React, {Component} from 'react';
import * as R from "ramda";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/es/Button/Button";
import TextField from "@material-ui/core/TextField";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import Snackbar from "../components/snackbar";
import Alert from "../components/alert";
import {requestAccessToken} from "../services/auth";

const checkField = (value, allowEmpty = false) => (allowEmpty || !R.isEmpty(value));

class Login extends Component {
  state = {
    email: "",
    password: "",
    emailError: false,
    passwordError: false,
  };

  connectUser() {
    const {email, password} = this.state;
    if (!checkField(email, false)) {
      return this.setState({emailError: true})
    }
    if (!checkField(password, false)) {
      return this.setState({passwordError: true})
    }
    return this.props.requestAccessToken(email, password)
      .then(() => this.props.history.push(`/operations/list`))
      .catch((error) => {
        if (R.pathEq(["response", "status"], 404, error)) {
          return this.setState({snackMessage: "Email ou mot de passe incorrect"})
        }
        return this.setState({snackMessage: "Une erreur est survenue. Veuillez réessayer."})
      });
  }

  handleChange = name => event => {
    return this.setState({
      [name + "Error"]: false,
      [name]: event.target.value,
    });
  };

  render() {
    const {email, password} = this.state;
    return (
      <Grid container justify="center" className="view">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <div className={"displaySmallScreen"}>
            <Alert type={"error"} message={<span>Ce site n'a pas été adapté aux petits écrans. Merci d'utiliser un ordinateur ou une tablette.</span>}/>
          </div>
          <Alert type={"warning"} message={<span>Ce site est en cours de développement. Les données sont supprimées toutes les 5 minutes. Vous serez automatiquement déconnectés.</span>}/>
          <Alert type={"info"} message={<span>Email : <strong>lecomptedetest@rienquepour.vous</strong><br/>Mot de passe : <strong>Lemotdepasse2020!</strong></span>}/>
          <Grid container direction="row" spacing={16}>
            <Grid item xs={12}>
              <TextField
                error={this.state.emailError || !checkField(email, true)}
                id="outlined-email"
                label="Email"
                value={email}
                onChange={this.handleChange('email')}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={this.state.passwordError || !checkField(password, true)}
                id="outlined-password"
                label="Mot de passe"
                type="password"
                value={password}
                onChange={this.handleChange('password')}
                margin="normal"
                variant="outlined"
                fullWidth
                onKeyPress={({key}) => key === 'Enter' ? this.connectUser() : null}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.connectUser()}
              >
                Se connecter
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            type="error"
            snackMessage={this.state.snackMessage}
            onClose={() => this.setState({snackMessage: null})}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  requestAccessToken,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Login));
