import React, {Component} from 'react';
import * as R from "ramda";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import FavoriteSelector from "../components/favoriteSelector";
import OperationForm from "../components/operationForm";
import Snackbar from "../components/snackbar";

import {getOperation, createOperation, operationToString} from "../services/operation";
import {getDecoratorsWithoutPagination} from "../services/decorator";

class AddOperation extends Component {
  state = {
    selectedTab: 'expense',
    favorite: null,
    snackMessage: null,
  };

  componentDidMount() {
    this.props.getDecoratorsWithoutPagination('category');
    this.props.getDecoratorsWithoutPagination('shop');
  }

  handleTabChange(e, value) {
    this.setState({selectedTab: value});
  }

  handleFavoriteSelect = (selectedFavorite) => {
    this.setState({favorite: null}, () => {
      if (selectedFavorite) {
        return this.props.getOperation(selectedFavorite.id)
          .then(({amount, description, shop, category}) => {
            const selectedCategory = R.find(R.propEq('id', category))(this.props.categories);
            const selectedShop = R.find(R.propEq('id', shop))(this.props.shops);
            this.setState({
              favorite: {amount, description, selectedCategory, selectedShop},
              selectedTab: amount < 0 ? 'expense' : 'income',
            });
          });
      }
    });
  };

  createOperation(componentState) {
    const {selectedTab} = this.state;
    const {amount, date, description, selectedCategory, selectedShop, marked, backToOperationList} = componentState;
    const isExpense = selectedTab === 'expense';
    const data = R.pipe(
      R.assoc('amount', Math.abs(amount) * (isExpense ? -1 : 1)),
      R.assoc('date', moment(date).format('YYYY-MM-DD')),
      R.assoc('description', description),
      R.assoc('shop', R.path(['id'], selectedShop)),
      R.assoc('category', R.path(['id'], selectedCategory)),
      R.assoc('marked', marked),
    )({});

    return this.props.createOperation(data)
      .then((operation) => {
        if (backToOperationList) {
          this.props.history.push(`/operations/list?created=${operation.id}`);
        } else {
          this.setState({snackMessage: `Opération ${operationToString(operation)} crée !`})
        }
      });
  }

  render() {
    const {selectedTab, favorite} = this.state;
    const {categories, shops} = this.props;
    const {amount, description, selectedCategory, selectedShop} = favorite || {};
    return (
      <Grid container direction="column" className="view" justify="center">
        <Grid container justify="center">
          <Paper>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, v) => this.handleTabChange(e, v)}
            >
              <Tab value="expense" label="Dépense"/>
              <Tab value="income" label="Entrée d'argent"/>
            </Tabs>
            <div className="form">
              {favorite &&
              <OperationForm
                amount={amount}
                date={moment()}
                description={description}
                selectedShop={selectedShop}
                selectedCategory={selectedCategory}
                shops={shops}
                categories={categories}
                isExpense={selectedTab === 'expense'}
                onSubmit={(data) => this.createOperation(data)}
              />
              }
              {!favorite &&
              <OperationForm
                shops={shops}
                categories={categories}
                isExpense={selectedTab === 'expense'}
                onSubmit={(data) => this.createOperation(data)}
              />
              }
              <div>
                <Divider variant="middle" className="divider"/>
                <FavoriteSelector
                  onChange={(fav) => this.handleFavoriteSelect(fav)}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        <Snackbar
          type="success"
          snackMessage={this.state.snackMessage}
          onClose={() => this.setState({snackMessage: null})}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.decorator.category.list,
    shops: state.decorator.shop.list,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createOperation,
  getDecoratorsWithoutPagination,
  getOperation,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOperation));
