import React, {Component} from 'react';
import moment from 'moment';
import * as R from 'ramda';
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableRow from "@material-ui/core/es/TableRow";
import TableBody from "@material-ui/core/es/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/es/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Snackbar from "../components/snackbar";
import DeletionDialog from "./deletionDialog";
import TableSortLabel from "./tableSortLabel";
import OperationMenu from "./operationMenu";

import {forceRefresh} from "../services/tool";
import {
  deleteOperation,
  getOperations,
  updateOperation,
  operationToString,
  setPage,
  setRowsPerPage,
  setSort
} from "../services/operation";

class OperationList extends Component {
  state = {
    confirmDeletionOpened: false,
  };

  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };

  handleChangeRowsPerPage = event => {
    this.props.setRowsPerPage(event.target.value);
  };

  openDeletionDialog = (operation) => {
    this.setState({
      confirmDeletionOpened: true,
      dialogOperation: R.pick(["id", "description", "amount"], operation),
    });
  };

  openRemoveFavDialog = (operation) => {
    this.setState({
      confirmRemoveFavOpened: true,
      dialogOperation: R.pick(["id", "description", "amount"], operation),
    });
  };

  confirmDeletion = () => {
    return this.props.deleteOperation(this.state.dialogOperation.id)
      .then((operation) => {
        this.setState({
          confirmDeletionOpened: false,
          dialogOperation: null,
          snackMessage: `${operationToString(operation)} supprimée !`,
        });
        this.props.forceRefresh();
        this.updateOperationList();
      });
  };

  confirmRemoveFav = () => {
    return this.props.updateOperation(this.state.dialogOperation.id, {favorite: false})
      .then((operation) => {
        this.setState({
          confirmRemoveFavOpened: null,
          snackMessage: `Favori ${operationToString(operation)} supprimé !`,
        });
        this.updateOperationList();
      });
  };

  cancelDeletion = () => {
    this.setState({
      confirmDeletionOpened: false,
      confirmRemoveFavOpened: false,
      dialogOperation: null,
    });
  };

  updateFavoriteStatus(operation) {
    return this.props.updateOperation(operation.id, {favorite: !operation.favorite})
      .then(() => this.updateOperationList());
  }

  updateMarkedStatus(operation) {
    return this.props.updateOperation(operation.id, {marked: !operation.marked})
      .then(() => this.updateOperationList());
  }

  updateOperationList() {
    const {page, rowsPerPage, predicate, sort} = this.props;
    return this.props.getOperations(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1, predicate, sort);
  }

  handleSortClick(column, direction) {
    this.props.setSort(column, direction);
  }

  componentDidMount() {
    this.props.setSort('date', 'desc');
  }

  render() {
    const {dialogOperation, confirmDeletionOpened, confirmRemoveFavOpened} = this.state;
    const {count, page, rowsPerPage, predicate, operations, categories, shops, sort} = this.props;
    const sortDirection = sort.direction.toLowerCase();
    const sortColumn = sort.column;
    return (
      <Grid className="component">
        {operations.length > 0 &&
        <Paper className="tablePaper">
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow>
                {predicate.favorite !== true &&
                <TableCell>
                  <TableSortLabel
                    orderBy={sortColumn}
                    direction={sortDirection}
                    value="date"
                    label="Date"
                    align="right"
                    onClick={(orderBy, order) => this.handleSortClick(orderBy, order)}
                  />
                </TableCell>
                }
                <TableCell align="right">
                  <TableSortLabel
                    orderBy={sortColumn}
                    direction={sortDirection}
                    value="amount"
                    label="Montant"
                    onClick={(orderBy, order) => this.handleSortClick(orderBy, order)}
                  />
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    orderBy={sortColumn}
                    direction={sortDirection}
                    value="description"
                    label="Description"
                    onClick={(orderBy, order) => this.handleSortClick(orderBy, order)}
                  />
                </TableCell>
                <TableCell align="right">Catégorie</TableCell>
                <TableCell align="right">Magasin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {operations.map(operation => {
                return (
                  <TableRow key={operation.id}>
                    {predicate.favorite !== true &&
                    <TableCell className={operation.marked ? "marked" : ""}>
                      {moment(operation.date).format("DD/MM/YYYY")}
                    </TableCell>
                    }
                    <TableCell align="right">{operation.amount} €</TableCell>
                    <TableCell align="right">{operation.description}</TableCell>
                    <TableCell align="right">{categories[operation.category]}</TableCell>
                    <TableCell align="right">{shops[operation.shop]}</TableCell>
                    {predicate.favorite === true &&
                    <TableCell align="right">
                      <Button color="primary" size="small" onClick={() => this.openRemoveFavDialog(operation)}>
                        Retirer
                      </Button>
                    </TableCell>
                    }
                    {predicate.favorite !== true &&
                    <TableCell align="right">
                      <OperationMenu
                        operation={operation}
                        editAction={() => this.props.history.push(`/operations/edit/${operation.id}`)}
                        deleteAction={() => this.openDeletionDialog(operation)}
                        favAction={() => this.updateFavoriteStatus(operation)}
                        markAction={() => this.updateMarkedStatus(operation)}
                      />
                    </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            style={{maxHeight: 50}}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        }
        {operations.length === 0 &&
        <Typography color="textSecondary">
          Aucune opération ne correspond aux filtres sélectionnés.
        </Typography>
        }
        {confirmDeletionOpened &&
        <DeletionDialog
          cancelAction={this.cancelDeletion}
          confirmAction={this.confirmDeletion}
          title={"Suppression d'une opération"}
          content={`Veuillez confirmer la suppression de ${operationToString(dialogOperation)}`}
        />
        }
        {confirmRemoveFavOpened &&
        <DeletionDialog
          cancelAction={this.cancelDeletion}
          confirmAction={this.confirmRemoveFav}
          title={"Suppression d'un favori"}
          content={`Supprimer le favori ${operationToString(dialogOperation)} ne supprimera ni l'opération d'origine, ni les opérations copiées depuis ce favori.`}
        />
        }
        <Snackbar
          type="success"
          snackMessage={this.state.snackMessage}
          onClose={() => this.setState({snackMessage: null})}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predicate: state.operation.predicate,
    operations: state.operation.list,
    categories: state.decorator.category.table,
    shops: state.decorator.shop.table,
    count: state.operation.meta.count,
    page: state.operation.meta.page || 0,
    sort: state.operation.meta.sort,
    rowsPerPage: state.operation.meta.rowsPerPage || 25,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteOperation,
  getOperations,
  forceRefresh,
  updateOperation,
  setPage,
  setSort,
  setRowsPerPage,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationList));
