import React, {useEffect, useState} from 'react';
import * as moment from "moment";

import Alert from "./alert";

const LogoutAlert = ({inputDate}) => {
  const [logoutDate, setLogoutDate] = useState("");
  const [logoutDelay, setLogoutDelay] = useState("");

  const setLogoutData = () => {
    const d = moment(inputDate).format("YYYY-MM-DD HH:mm:ss");
    const c14 = Number(d[14]);
    const c15 = Number(d[15]);
    let _logoutDate;
    if (c15 < 5) {
      _logoutDate = moment(d.substr(0, 15) + '5:00');
    } else if (c14 === 5) {
      _logoutDate = moment(d.substr(0, 14) + '00:00').add({hours: 1});
    } else {
      _logoutDate = moment(d.substr(0, 14) + (c14 + 1) + '0:00');
    }
    setLogoutDate(_logoutDate.format("HH:mm:ss"));
    setLogoutDelay(_logoutDate.diff(moment(d)) / 1000);
  };

  useEffect(() => {
    setLogoutData();
    setInterval(() => {
      setLogoutData();
    }, 1000);
  }, []);
  return (
    <Alert type={"warning"} message={
      <span>
        Toutes les sessions seront déconnectées à {logoutDate}  (dans {logoutDelay} secondes).
      </span>
    }/>
  );
};

export default LogoutAlert;
