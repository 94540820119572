import * as R from 'ramda';

const initialState = {
  list: [],
  notPaginatedList: [],
  favorites: [],
  meta: {
    count: 0,
    page: 0,
    rowsPerPage: 5,
    sort: {
      direction: 'DESC',
      column: 'id',
    }
  },
  predicate: {}
};

const OperationReducer = function (previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case 'CLEAR_OPERATION':
      return initialState;
    case 'SET_FAVORITE_LIST':
      return R.pipe(
        R.assoc('favorites', action.operations),
      )(newState);
    case 'SET_NOT_PAGINATED_OPERATION_LIST':
      return R.assoc("notPaginatedList", action.operations, previousState);
    case 'SET_OPERATION_LIST':
      return R.pipe(
        R.assoc('list', action.operations),
        R.assocPath(["meta", "count"], action.count)
      )(newState);
    case 'SET_SORT':
      return R.assocPath(["meta", "sort"], action.sort, previousState);
    case 'SET_PAGE':
      return R.assocPath(["meta", "page"], action.page, previousState);
    case 'SET_ROWS_PER_PAGE':
      return R.assocPath(["meta", "rowsPerPage"], action.rowsPerPage, previousState);
    case 'SET_PREDICATE':
      return R.pipe(
        R.assocPath(["meta", "page"], 0),
        R.assoc("predicate", action.predicate)
      )(newState);
    case 'ADD_TO_PREDICATE':
      return R.pipe(
        R.assocPath(["meta", "page"], 0),
        R.assoc("predicate", R.mergeAll([newState.predicate, action.predicate]))
      )(newState);
    case 'REMOVE_FROM_PREDICATE':
      return R.dissocPath(["predicate", action.field], previousState);
    case 'RESET_PREDICATE':
      return R.assoc("predicate", {}, newState);
    default:
      return previousState;
  }
};

export default OperationReducer;
