import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default class Snackbar extends Component {
  render() {
    const {data, title} = this.props;
    const {input, output} = data;
    return (
      <Grid item xs={6}>
        <Paper style={{padding: 10}}>
          <Typography variant="h5">
            {title}
          </Typography>
          <Typography variant="p">
            <span style={{color: "green", fontWeight: 'bold'}}>+ {input.sum} €</span> / {input.count} opérations
          </Typography>
          <Typography variant="p">
            <span style={{color: "red", fontWeight: 'bold'}}>- {Math.abs(output.sum)} €</span> / {output.count} opérations
          </Typography>
        </Paper>
      </Grid>
    );
  }
}