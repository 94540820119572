import React, {Component} from 'react';
import * as moment from 'moment';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {
  createDecorator,
  getDecorator,
  updateDecorator,
  deleteDecorator,
  getPluralizedDecoratorName
} from "../services/decorator";
import {getCountAndSum} from "../services/operation";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {DeleteOutline, Edit} from "@material-ui/icons";

import DeletionDialog from "./deletionDialog";
import DecoratorDetail from "./decoratorDetail";
import * as R from "ramda";

class DecoratorOverview extends Component {
  state = {
    current: {
      id: -1,
      name: "",
      description: ""
    },
    confirmDeletionOpened: false,
    decoratorName: this.props.type,
    pluralizedDecoratorName: getPluralizedDecoratorName(this.props.type),
    currentDecoratorId: this.props.id,
    thisYearIn: {count: 0, sum: "0.00"},
    thisYearOut: {count: 0, sum: "0.00"},
    thisMonthIn: {count: 0, sum: "0.00"},
    thisMonthOut: {count: 0, sum: "0.00"},
    fromBeginIn: {count: 0, sum: "0.00"},
    fromBeginOut: {count: 0, sum: "0.00"},
    sevenLastDayIn: {count: 0, sum: "0.00"},
    sevenLastDayOut: {count: 0, sum: "0.00"},
    thirtyLastDayIn: {count: 0, sum: "0.00"},
    thirtyLastDayOut: {count: 0, sum: "0.00"},
    prevMonthIn: {count: 0, sum: "0.00"},
    prevMonthOut: {count: 0, sum: "0.00"},
  };

  componentDidMount() {
    const {currentDecoratorId, decoratorName} = this.state;

    // this year
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().startOf('year').format("YYYYMMDD"),
      end: moment().endOf('year').format("YYYYMMDD"),
    })
      .then((thisYearIn) => {
        this.setState({thisYearIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().startOf('year').format("YYYYMMDD"),
      end: moment().endOf('year').format("YYYYMMDD"),
    })
      .then((thisYearOut) => {
        this.setState({thisYearOut})
      });

    // this month
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().startOf('month').format("YYYYMMDD"),
      end: moment().endOf('month').format("YYYYMMDD"),
    })
      .then((thisMonthIn) => {
        this.setState({thisMonthIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().startOf('month').format("YYYYMMDD"),
      end: moment().endOf('month').format("YYYYMMDD"),
    })
      .then((thisMonthOut) => {
        this.setState({thisMonthOut})
      });

    // from begin
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
    })
      .then((fromBeginIn) => {
        this.setState({fromBeginIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
    })
      .then((fromBeginOut) => {
        this.setState({fromBeginOut})
      });

    // 7 last days
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(7, 'days').startOf('day').format("YYYYMMDD"),
      end: moment().format("YYYYMMDD"),
    })
      .then((sevenLastDayIn) => {
        this.setState({sevenLastDayIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(7, 'days').startOf('day').format("YYYYMMDD"),
      end: moment().format("YYYYMMDD"),
    })
      .then((sevenLastDayOut) => {
        this.setState({sevenLastDayOut})
      });

    // 30 last days
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(1, 'months').startOf('day').format("YYYYMMDD"),
      end: moment().format("YYYYMMDD"),
    })
      .then((thirtyLastDayIn) => {
        this.setState({thirtyLastDayIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(1, 'months').startOf('day').format("YYYYMMDD"),
      end: moment().format("YYYYMMDD"),
    })
      .then((thirtyLastDayOut) => {
        this.setState({thirtyLastDayOut})
      });

    // previous month
    this.props.getCountAndSum({
      amount: "in",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(1, 'months').startOf('month').format("YYYYMMDD"),
      end: moment().subtract(1, 'months').endOf('month').format("YYYYMMDD"),
    })
      .then((prevMonthIn) => {
        this.setState({prevMonthIn})
      });
    this.props.getCountAndSum({
      amount: "out",
      [this.state.decoratorName]: currentDecoratorId,
      start: moment().subtract(1, 'months').startOf('month').format("YYYYMMDD"),
      end: moment().subtract(1, 'months').endOf('month').format("YYYYMMDD"),
    })
      .then((prevMonthOut) => {
        this.setState({prevMonthOut})
      });

    if (currentDecoratorId) {
      return this.props.getDecorator(decoratorName, currentDecoratorId)
        .then((decorator) => {
          this.setState({
            current: {
              id: decorator.id,
              name: decorator.name,
              description: decorator.description,
              createdAt: decorator.createdAt,
            }
          });
        });
    }
  }

  handleOpenDialog = (decorator) => {
    this.setState({
      confirmDeletionOpened: true,
      removingDecorator: R.pick(["id", "name"], decorator),
    });
  };

  confirmDeletion = () => {
    return this.props.deleteDecorator(this.state.decoratorName, this.state.removingDecorator.id)
      .then(() => {
        this.props.history.push(`/${this.state.pluralizedDecoratorName}/list?deleted=${this.state.removingDecorator.id}`);
        this.setState({
          confirmDeletionOpened: false,
          removingDecorator: null,
        });
      });
  };

  cancelDeletion = () => {
    this.setState({
      confirmDeletionOpened: false,
      removingDecorator: null,
    });
  };

  render() {
    const {current, pluralizedDecoratorName, decoratorName, confirmDeletionOpened, removingDecorator} = this.state;
    const {id, name, description, createdAt} = current;
    return (
      <div className="component">
        <Card>
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe">
                {name[0]}
              </Avatar>
            }
            title={name}
            subheader={`Crée le ${moment(createdAt).format("DD/MM/YYYY")}`}
          />
          <CardContent>
            <Typography component="p">
              {description || "Pas de description"}
            </Typography>
            <Divider variant="middle" className="divider"/>
            <Grid container spacing={16}>
              <DecoratorDetail
                title="Cette année"
                data={{
                  input: this.state.thisYearIn,
                  output: this.state.thisYearOut,
                }}
              />
              <DecoratorDetail
                title="7 derniers jours"
                data={{
                  input: this.state.sevenLastDayIn,
                  output: this.state.sevenLastDayOut,
                }}
              />
            </Grid>
            <Grid container spacing={16}>
              <DecoratorDetail
                title="Mois en cours"
                data={{
                  input: this.state.thisMonthIn,
                  output: this.state.thisMonthOut,
                }}
              />
              <DecoratorDetail
                title="Depuis un mois"
                data={{
                  input: this.state.thirtyLastDayIn,
                  output: this.state.thirtyLastDayOut,
                }}
              />
            </Grid>
            <Grid container spacing={16}>
              <DecoratorDetail
                title="Depuis le début"
                data={{
                  input: this.state.fromBeginIn,
                  output: this.state.fromBeginOut,
                }}
              />
              <DecoratorDetail
                title="Mois précédent"
                data={{
                  input: this.state.prevMonthIn,
                  output: this.state.prevMonthOut,
                }}
              />
            </Grid>
          </CardContent>
          <CardActions disableActionSpacing>
            <IconButton
              aria-label="Edit"
              onClick={() => this.props.history.push(`/${pluralizedDecoratorName}/edit/${id}`)}
            >
              <Edit fontSize="small"/>
            </IconButton>
            <IconButton aria-label="Delete" onClick={() => this.handleOpenDialog(current)}>
              <DeleteOutline fontSize="small"/>
            </IconButton>
          </CardActions>
        </Card>
        {confirmDeletionOpened &&
        <DeletionDialog
          cancelAction={this.cancelDeletion}
          confirmAction={this.confirmDeletion}
          title={`Suppression ${decoratorName === 'shop' ? "d'un magasin" : "d'une catégorie"}`}
          content={`Veuillez confirmer la suppression ${decoratorName === 'shop' ? "du magasin" : "de la catégorie"} ${removingDecorator.name}`}
        />
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createDecorator,
  deleteDecorator,
  getDecorator,
  getCountAndSum,
  updateDecorator,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(DecoratorOverview));

