import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";

import DecoratorForm from "../components/decoratorForm";

export default class EditShop extends Component {
  render() {
    return (
      <Grid container justify="center" className="view">
        <Grid item xs={6}>
          <DecoratorForm type="shop" id={this.props.match.params.id}/>
        </Grid>
      </Grid>
    );
  }
}
