import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import {
  Category,
  ExitToApp,
  FormatListBulleted,
  Help,
  LibraryAdd, PersonAdd, PowerSettingsNew,
  Settings,
  ShoppingBasket,
  Star,
} from "@material-ui/icons";
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Tab from './tab';
import {withRouter} from "react-router-dom";
import {changeTab} from "../services/navigation";
import {disconnect} from "../services/auth";

const styles = () => ({
  tabsIndicator: {
    backgroundColor: 'whitesmoke',
  }
});

class Menu extends Component {
  handleChange = (event, path) => {
    if (path === "/logout") {
      return this.props.disconnect();
    }
    return this.props.changeTab(path)
      .then(() => this.props.history.push(path));
  };

  render() {
    const {isConnected, currentPath, classes} = this.props;
    return (
      <AppBar position="static" color="default">
        <Tabs
          value={currentPath}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          classes={{indicator: classes.tabsIndicator}}
          className="tabBar"
        >
          <Tab visible={isConnected} value="/operations/list" label="Mes opérations" icon={<FormatListBulleted/>}/>
          <Tab visible={isConnected} value="/operations/edit" label="Ajouter une opération" icon={<LibraryAdd/>}/>
          <Tab visible={isConnected} value="/categories/list" label="Catégories" icon={<Category/>}/>
          <Tab visible={isConnected} value="/shops/list" label="Magasins" icon={<ShoppingBasket/>}/>
          <Tab visible={isConnected} value="/favorites/list" label="Mes favoris" icon={<Star/>}/>
          <Tab visible={isConnected} label="Paramètres" icon={<Settings/>} disabled/>
          <Tab visible={isConnected} value="/logout" label="Déconnexion" icon={<ExitToApp/>}/>
          <Tab visible={!isConnected} value="/login" label="Connexion" icon={<PowerSettingsNew/>}/>
          <Tab visible={!isConnected} value="/signup" label="Inscription" icon={<PersonAdd/>}/>
          <Tab label="Aide" icon={<Help/>} disabled/>
        </Tabs>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConnected: !!state.session.accessToken,
    currentPath: state.navigation.currentPath,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeTab,
  disconnect,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu)));
