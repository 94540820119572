import React, {Component} from "react";
import * as R from "ramda";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Select from 'react-select';
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getDecoratorsWithoutPagination} from "../services/decorator";
import {getOperations, addToPredicate, resetPredicate, print} from "../services/operation";

const checkAmount = (amount, allowEmpty = false) => (allowEmpty || !R.isEmpty(amount)) && !isNaN(amount);

class FilterBar extends Component {
  state = {
    shop: null,
    category: null,
    amount: "",
    start: moment('2017-01-01'),
    end: moment(),
  };

  componentDidMount() {
    this.props.resetPredicate();
    this.props.getDecoratorsWithoutPagination('category');
    this.props.getDecoratorsWithoutPagination('shop');
  }

  resetFilters() {
    this.props.resetPredicate();
    this.setState({
      shop: null,
      category: null,
      amount: "",
      start: moment().subtract({years: 1}),
      end: moment(),
    });
  }

  handleChange = (type) => (e) => {
    const {addToPredicate} = this.props;
    if (type === 'shop' || type === 'category') {
      this.setState({[type]: e}, () => addToPredicate({[type]: R.prop('id', e)}));
    } else if (type === 'amount') {
      this.setState({amount: e.target.value.replace(/,/, '.')});
    } else if (type === 'start' || type === 'end') {
      this.setState({[type]: moment(e)}, () => {
        addToPredicate({[type]: this.state[type].format("YYYYMMDD")});
      });
    }
  };

  handleQuickDate = (value) => () => {
    let start, end;
    if (value === '7_LAST') {
      start = moment().subtract(7, 'days').startOf('day');
      end = moment();
    } else if (value === '30_LAST') {
      start = moment().subtract(1, 'months').startOf('day');
      end = moment();
    } else if (value === 'CURRENT_MONTH') {
      start = moment().startOf('month');
      end = moment().endOf('month');
    } else if (value === 'PREVIOUS_MONTH') {
      start = moment().subtract(1, 'months').startOf('month');
      end = moment().subtract(1, 'months').endOf('month');
    }
    this.setState({
      start, end
    }, () => {
      this.props.addToPredicate({
        start: this.state.start.format('YYYYMMDD'),
        end: this.state.end.format('YYYYMMDD')
      });
    });
  };

  render() {
    const {amount, start, end, category, shop} = this.state;
    const {categories, shops} = this.props;
    return (
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelDetails>
          <Grid container spacing={16}>
            <Grid container spacing={16}>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    margin="normal"
                    label="Date de début"
                    leftArrowIcon={<KeyboardArrowLeft/>}
                    rightArrowIcon={<KeyboardArrowRight/>}
                    value={start}
                    labelFunc={date => date === null ? '' : moment(date).format('DD/MM/YYYY')}
                    onChange={this.handleChange('start')}
                    variant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    margin="normal"
                    label="Date de fin"
                    leftArrowIcon={<KeyboardArrowLeft/>}
                    rightArrowIcon={<KeyboardArrowRight/>}
                    value={end}
                    labelFunc={date => date === null ? '' : moment(date).format('DD/MM/YYYY')}
                    onChange={this.handleChange('end')}
                    variant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} className="select" style={{marginTop: 16}}>
                <Select
                  id="category"
                  placeholder={"Catégorie"}
                  value={category}
                  onChange={this.handleChange("category")}
                  options={categories}
                  isClearable
                />
              </Grid>
              <Grid item xs={3} className="select" style={{marginTop: 16}}>
                <Select
                  id="shop"
                  placeholder={"Magasin"}
                  value={shop}
                  onChange={this.handleChange("shop")}
                  options={shops}
                  isClearable
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  error={this.state.amountError || !checkAmount(amount, true)}
                  id="outlined-amount"
                  label="Montant"
                  value={amount}
                  onChange={this.handleChange('amount')}
                  onBlur={() => this.props.addToPredicate({amount: this.state.amount})}
                  onKeyPress={({key}) => key === 'Enter' ? this.props.addToPredicate({amount: this.state.amount}) : null}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={8}>
              <Grid item>
                <Button variant="outlined" onClick={this.handleQuickDate('7_LAST')}>7 derniers jours</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={this.handleQuickDate('30_LAST')}>Depuis un mois</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={this.handleQuickDate('CURRENT_MONTH')}>Mois en cours</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={this.handleQuickDate('PREVIOUS_MONTH')}>Mois précédent</Button>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        <Divider/>
        <ExpansionPanelActions style={{height: 18}}>
          <Button
            size="small"
            onClick={() => this.resetFilters()}
          >
            Réinitialiser les filtres
          </Button>
          <Button size="small" color="primary" onClick={() => this.props.print()}>
            Imprimer
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.decorator.category.list,
    shops: state.decorator.shop.list,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  addToPredicate,
  resetPredicate,
  getDecoratorsWithoutPagination,
  getOperations,
  print,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
