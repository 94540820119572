import qs from 'query-string';
import * as JsPdf from 'jspdf';
import * as P from 'bluebird';
import * as R from 'ramda';
import * as moment from 'moment';

import 'jspdf-autotable';

import requestService from './request';

export const getSum = (predicate) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/operation/sum?${qs.stringify(predicate)}`,
  })
    .then(({data}) => data.sum.toFixed(2));
};

export const getCount = (predicate) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/operation/count?${qs.stringify(predicate)}`,
  })
    .then(({data}) => data.count);
};

export const getCountAndSum = (predicate) => (dispatch, getState) => {
  const sumPromise = getSum(predicate)(dispatch, getState);
  const countPromise = getCount(predicate)(dispatch, getState);
  return P.all([sumPromise, countPromise])
    .spread((sum, count) => ({sum, count}));
};

export const getOperations = (first, last, predicate = {}, sort = {}, noDispatch = false) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/operation?${qs.stringify(predicate)}`,
    headers: {
      Range: `items=${first}-${last}`,
      sort: `${sort.column} ${sort.direction}`,
    }
  })
    .then(({data: operations, headers}) => {
      const rangeHeader = headers["content-range"];
      const [first, last, count] = (rangeHeader.match(/items ([0-9]*)-([0-9]*)\/([0-9]*)/) || []).slice(1);
      if (!noDispatch) {
        dispatch({type: 'SET_OPERATION_LIST', operations, count: Number(count)})
      }
      return {
        operations,
        first: Number(first),
        last: Number(last),
        count: Number(count),
      }
    });
};

export const getOperation = (id) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/operation/${id}`,
  })
    .then(({data}) => data);
};

export const getOperationsWithoutPagination = (_first = 0, limit = 9, predicate = {}, sort = {direction: "DESC", column: "id"}, data = []) => (dispatch, getState) => {
  return getOperations(_first, _first + limit, predicate, sort, true)(dispatch, getState)
    .then(({operations, count, first, last}) => {
      data.push(...operations);
      if (last < count - 1) {
        return getOperationsWithoutPagination(last + 1, _first + limit, predicate, sort, data)(dispatch, getState);
      }
      dispatch({type: 'SET_NOT_PAGINATED_OPERATION_LIST', operations: data});
      return {
        items: data
      };
    })
};

export const setPage = (page) => ({type: 'SET_PAGE', page});

export const setRowsPerPage = (rowsPerPage) => ({type: 'SET_ROWS_PER_PAGE', rowsPerPage});

export const getFavoritesWithoutPagination = (_first = 0, limit = 9, predicate = {}, sort = {direction: "DESC", column: "id"}, data = []) => (dispatch, getState) => {
  return getOperations(_first, _first + limit, predicate, sort, true)(dispatch, getState)
    .then(({operations, count, first, last}) => {
      data.push(...operations);
      if (last < count - 1) {
        return getFavoritesWithoutPagination(last + 1, _first + limit, predicate, sort, data)(dispatch, getState);
      }
      dispatch({type: 'SET_FAVORITE_LIST', operations: data})
    })
};

export const createOperation = (data) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'POST',
    path: `/operation`,
    data,
  })
    .then(({data}) => data);
};

export const updateOperation = (id, data) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'PUT',
    path: `/operation/${id}`,
    data,
  })
    .then(({data}) => data);
};

export const deleteOperation = (id) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'DELETE',
    path: `/operation/${id}`,
  })
    .then(({data}) => data);
};

export const setSort = (column, direction) => (dispatch) => {
  dispatch({type: 'SET_SORT', sort: {column, direction: direction.toUpperCase()}});
};

export const setPredicate = (predicate) => (dispatch) => {
  dispatch({type: 'SET_PREDICATE', predicate});
};

export const addToPredicate = (predicate) => (dispatch) => {
  dispatch({type: 'ADD_TO_PREDICATE', predicate});
};

export const resetPredicate = () => (dispatch) => {
  dispatch({type: 'RESET_PREDICATE'});
};

export const removeFromPredicate = (fields) => (dispatch) => {
  fields.forEach((field) => dispatch({type: 'REMOVE_FROM_PREDICATE', field}));
};

export const operationToString = ({amount, description}) => {
  if (description) {
    return `"${description}" (${amount}€)`;
  }
  return `"${amount < 0 ? 'dépense de' : 'entrée d\'argent de'} ${amount}€"`;
};

export const predicateToString = ({start, end, amount, category, shop}, categories, shops) => {
  let str = '';
  if (start && end) {
    str += `Du ${moment(start).format("DD/MM/YYYY")} au ${moment(end).format("DD/MM/YYYY")} `;
  }
  if (amount) {
    str += str.length > 0 ? " - " : "";
    str += `Montant de ${amount} €`;
  }
  if (category) {
    str += str.length > 0 ? " - " : "";
    str += `Catégorie : ${categories[category]}`;
  }
  if (shop) {
    str += str.length > 0 ? " - " : "";
    str += `Magasin : ${shops[shop]}`;
  }
  return str
};

export const print = () => (dispatch, getState) => {
  const doc = new JsPdf('p', 'pt');
  const columns = ['', 'Montant', 'Date', 'Description', 'Categorie', 'Magasin'];
  const {predicate, meta} = getState().operation;
  const {category: categories, shop: shops} = getState().decorator;

  return getOperationsWithoutPagination(0, 9, predicate, meta.sort)(dispatch, getState)
    .then(({items}) => items)
    .map(operation => R.pipe(
      R.assoc('category', categories.table[operation.category]),
      R.assoc('shop', shops.table[operation.shop]),
    )(operation))
    .map((operation) => {
      const {marked, amount, date, description, category, shop} = operation;
      return [marked ? 'X' : '', `${amount} €`, moment(date).format('DD/MM/YYYY'), description, category, shop];
    })
    .then(rows => {
      doc.autoTable({
        head: [columns],
        body: rows,
        styles: {overflow: 'linebreak'},
        margin: {top: 30},
        theme: 'striped'
      });
    })
    .then(() => {
      for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`${i}`, 560, 820);
        doc.setFontSize(10);
        doc.text(predicateToString(predicate, categories.table, shops.table), 20, 820);
      }
      doc.autoPrint();
      doc.save(`operations ${moment().format('DD-MM-YYYY HH:mm:ss')}.pdf`);
    });
};
