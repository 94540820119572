import React, {Component} from 'react';
import * as R from "ramda";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Snackbar from "../components/snackbar";
import FilterOperation from "../components/filterBar";
import OperationList from "../components/operationList";
import PredicateBar from "../components/predicateBar";

import {
  getOperation,
  getOperations,
  operationToString,
  setPredicate,
  getOperationsWithoutPagination,
} from "../services/operation";

class MyOperations extends Component {
  state = {
    snackMessage: null,
  };

  handleOpenDialog = (decorator) => {
    this.setState({
      confirmDeletionOpened: true,
      removingDecorator: R.pick(["id", "name"], decorator),
    });
  };

  componentDidUpdate(prevProps) {
    const {page, rowsPerPage, predicate, sort} = this.props;
    const predicateChange = !R.equals(prevProps.predicate, predicate);
    const sortChange = !R.equals(prevProps.sort, sort);
    const rowsPerPageChange = !R.equals(prevProps.rowsPerPage, rowsPerPage);
    const pageChange = !R.equals(prevProps.page, page);
    if (predicateChange || sortChange || rowsPerPageChange || pageChange) {
      this.props.getOperations(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1, predicate, sort);
      this.props.getOperationsWithoutPagination(0, 9, predicate);
    }
  }

  componentDidMount() {
    this.props.setPredicate({});
    const {page, rowsPerPage, predicate, sort} = this.props;
    this.props.getOperations(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1, predicate, sort);
    if (this.props.history.location.search) {
      const [lastAction, lastId] = (this.props.history.location.search.match(/(created|updated)=([0-9]*)/) || []).slice(1);
      this.props.history.push(this.props.history.location.pathname);
      if (lastAction && lastId) {
        return this.props.getOperation(lastId)
          .then((operation) => {
            const action = lastAction === 'created' ? 'créée' : 'modifiée';
            this.setState({snackMessage: `Opération ${operationToString(operation)} ${action} !`});
          });
      }
    }
  }

  render() {
    return (
      <Grid container direction="column" justify="center" className="view">
        <Grid container justify="center">
          <Grid item xs={8}>
            <FilterOperation/>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{paddingTop: 10, paddingBottom: 10}}>
          <Grid item lg={10} md={12}>
            <PredicateBar/>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item lg={10} md={12}>
            <OperationList/>
          </Grid>
        </Grid>
        <Snackbar
          type="success"
          snackMessage={this.state.snackMessage}
          onClose={() => this.setState({snackMessage: null})}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predicate: state.operation.predicate,
    operations: state.operation.list,
    categories: state.decorator.category.table,
    shops: state.decorator.shop.table,
    sort: state.operation.meta.sort,
    count: state.operation.meta.count,
    page: state.operation.meta.page || 0,
    rowsPerPage: state.operation.meta.rowsPerPage || 5,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getOperation,
  getOperationsWithoutPagination,
  getOperations,
  setPredicate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyOperations);
