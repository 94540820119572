import React, {Component} from 'react';
import * as R from "ramda";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableRow from "@material-ui/core/es/TableRow";
import TableBody from "@material-ui/core/es/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/es/Table";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import {Details, Edit, DeleteOutline} from '@material-ui/icons';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import DeletionDialog from "./deletionDialog";
import Snackbar from "./snackbar";
import TableSortLabel from "./tableSortLabel";
import {deleteDecorator, getDecorator, getDecorators, getPluralizedDecoratorName} from "../services/decorator";

class DecoratorList extends Component {
  state = {
    items: [],
    rowsPerPage: 5,
    count: 0,
    page: 0,
    confirmDeletionOpened: false,
    decoratorName: this.props.type,
    pluralizedDecoratorName: getPluralizedDecoratorName(this.props.type),
    snackMessage: null,
    orderBy: 'name',
    order: 'asc',
  };

  handleChangePage = (event, page) => {
    this.setState({page}, () => this.updateDecoratorList());
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value}, () => this.updateDecoratorList());
  };

  handleOpenDialog = (decorator) => {
    this.setState({
      confirmDeletionOpened: true,
      removingDecorator: R.pick(["id", "name"], decorator),
    });
  };

  confirmDeletion = () => {
    return this.props.deleteDecorator(this.state.decoratorName, this.state.removingDecorator.id)
      .then(({name}) => {
        this.setState({
          confirmDeletionOpened: false,
          removingDecorator: null,
          snackMessage: `${this.state.decoratorName === 'shop' ? `Magasin ${name} supprimé !` : `Catégorie ${name} supprimée !`}`,
        });
        this.updateDecoratorList();
      });
  };

  cancelDeletion = () => {
    this.setState({
      confirmDeletionOpened: false,
      removingDecorator: null,
    });
  };

  componentDidMount() {
    this.updateDecoratorList();
    if (this.props.history.location.search) {
      const [lastAction, lastId] = (this.props.history.location.search.match(/(created|updated|deleted)=([0-9]*)/) || []).slice(1);
      this.props.history.push(this.props.history.location.pathname);
      if (lastAction && lastId) {
        return this.props.getDecorator(this.state.decoratorName, lastId)
          .then(({name}) => {
            const action = lastAction === 'created' ? 'créé' : lastAction === 'updated' ? 'modifié' : 'supprimé';
            this.setState({snackMessage: `${this.state.decoratorName === 'shop' ? `Magasin ${name || `#${lastId}`} ${action} !` : `Catégorie ${name || `#${lastId}`} ${action}e !`}`})
          });
      }
    }
  }

  updateDecoratorList() {
    const {page, rowsPerPage, decoratorName, orderBy, order} = this.state;
    return this.props.getDecorators(decoratorName, page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1, orderBy, order)
      .then(({items, count}) => {
        this.setState({items, count});
      });
  }

  handleSortClick(orderBy, order) {
    this.setState({orderBy, order}, () => this.updateDecoratorList());
  }

  render() {
    const {page, count, rowsPerPage, confirmDeletionOpened, decoratorName, pluralizedDecoratorName, removingDecorator} = this.state;
    const {orderBy, order} = this.state;
    return (
      <Grid className="component" item xs={5}>
        <Paper className="tablePaper">
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow>
                <TableCell align="right">
                  <TableSortLabel
                    orderBy={orderBy}
                    direction={order}
                    value="id"
                    label="Id"
                    onClick={(orderBy, order) => this.handleSortClick(orderBy, order)}
                  />
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    orderBy={orderBy}
                    direction={order}
                    value="name"
                    label="Nom"
                    onClick={(orderBy, order) => this.handleSortClick(orderBy, order)}
                  />
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {this.state.items.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="right">{item.id}</TableCell>
                    <TableCell align="right">{item.name}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="View"
                        onClick={() => this.props.history.push(`/${pluralizedDecoratorName}/view/${item.id}`)}
                      >
                        <Details fontSize="small"/>
                      </IconButton>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => this.props.history.push(`/${pluralizedDecoratorName}/edit/${item.id}`)}
                      >
                        <Edit fontSize="small"/>
                      </IconButton>
                      <IconButton aria-label="Delete" onClick={() => this.handleOpenDialog(item)}>
                        <DeleteOutline fontSize="small"/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        {confirmDeletionOpened &&
        <DeletionDialog
          cancelAction={this.cancelDeletion}
          confirmAction={this.confirmDeletion}
          title={`Suppression ${decoratorName === 'shop' ? "d'un magasin" : "d'une catégorie"}`}
          content={`Veuillez confirmer la suppression ${decoratorName === 'shop' ? "du magasin" : "de la catégorie"} ${removingDecorator.name}`}
        />
        }
        <Snackbar
          type="success"
          snackMessage={this.state.snackMessage}
          onClose={() => this.setState({snackMessage: null})}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteDecorator,
  getDecorator,
  getDecorators,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(DecoratorList));
