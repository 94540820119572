import * as R from 'ramda';

const initialState = {
  accessToken: "",
  accessTokenExpirationDate: "",
  refreshToken: "",
};

const SessionReducer = function (previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case 'REGISTER_TOKEN':
      return R.pipe(
        R.assoc('accessToken', action.accessToken),
        R.assoc('accessTokenExpirationDate', action.accessTokenExpirationDate),
        R.assoc('refreshToken', action.refreshToken),
      )(newState);
    case 'REVOKE_TOKEN':
      return initialState;
    default:
      return previousState;
  }
};

export default SessionReducer;
