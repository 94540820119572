import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {withRouter} from 'react-router-dom';

import DecoratorList from "../components/decoratorList";

class MyShops extends Component {
  render() {
    return (
      <Grid container direction="column" className="view">
        <Grid container justify="center">
          <DecoratorList type="shop"/>
        </Grid>
        <Divider variant="middle" className="divider"/>
        <Grid container justify="center">
          <Button variant="contained" color="secondary" onClick={() => this.props.history.push('/shops/edit')}>
            Ajouter un nouveau magasin
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(MyShops);
