import React, {Component} from 'react';
import * as R from "ramda";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import OperationForm from "../components/operationForm";

import {getOperation, updateOperation} from "../services/operation";
import {getDecoratorsWithoutPagination} from "../services/decorator";

class EditOperation extends Component {
  state = {
    selectedTab: 'expense',
    current: {},
  };

  componentDidMount() {
    this.props.getDecoratorsWithoutPagination('category');
    this.props.getDecoratorsWithoutPagination('shop');

    return this.props.getOperation(this.props.match.params.id)
      .then(({id, amount, date, description, shop, category}) => {
        const selectedCategory = R.find(R.propEq('id', category))(this.props.categories);
        const selectedShop = R.find(R.propEq('id', shop))(this.props.shops);
        this.setState({
          current: {id, amount, date, description, selectedCategory, selectedShop},
          selectedTab: amount < 0 ? 'expense' : 'income',
        });
      });
  }

  updateOperation(componentState) {
    const {selectedTab} = this.state;
    const {id, amount, date, description, selectedCategory, selectedShop} = componentState;
    const isExpense = selectedTab === 'expense';
    const data = R.pipe(
      R.assoc('amount', Math.abs(amount) * (isExpense ? -1 : 1)),
      R.assoc('date', moment(date).format('YYYY-MM-DD')),
      R.assoc('description', description),
      R.assoc('shop', R.path(['id'], selectedShop)),
      R.assoc('category', R.path(['id'], selectedCategory))
    )({});

    return this.props.updateOperation(id, data)
      .then((operation) => this.props.history.push(`/operations/list?updated=${operation.id}`));
  }

  handleTabChange(e, value) {
    this.setState({selectedTab: value});
  }

  render() {
    const {selectedTab, current} = this.state;
    const {categories, shops} = this.props;
    const {id, amount, date, description, selectedCategory, selectedShop} = current;
    return (
      <Grid container direction="column" className="view" justify="center">
        <Grid container justify="center">
          <Paper>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, v) => this.handleTabChange(e, v)}
            >
              <Tab value="expense" label="Dépense"/>
              <Tab value="income" label="Entrée d'argent"/>
            </Tabs>
            <div className="form">
              {R.isEmpty(current) &&
              <OperationForm/>
              }
              {!R.isEmpty(current) &&
              <OperationForm
                id={id}
                amount={amount}
                date={date}
                description={description}
                selectedShop={selectedShop}
                selectedCategory={selectedCategory}
                shops={shops}
                categories={categories}
                isExpense={selectedTab === 'expense'}
                onSubmit={(data) => this.updateOperation(data)}
              />
              }
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.decorator.category.list,
    shops: state.decorator.shop.list,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getDecoratorsWithoutPagination,
  getOperation,
  updateOperation,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOperation));
