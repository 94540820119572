import React, {useEffect, useState} from 'react';

import {Switch, Redirect, Route, Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import Menu from "./components/menu";
import AddOperation from "./views/addOperation";
import CategoryOverview from "./views/categoryOverview";
import EditCategory from "./views/editCategory";
import EditOperation from "./views/editOperation";
import EditShop from "./views/editShop";
import Login from "./views/login";
import MyCategories from "./views/myCategories";
import MyOperations from "./views/myOperations";
import MyFavorites from "./views/myFavorites";
import MyShops from "./views/myShops";
import SignUp from "./views/signup";
import ShopOverview from "./views/shopOverview";
import Mentions from "./views/mentions";
import LogoutAlert from "./components/logoutAlert";
import CookieConsent from "./components/cookieConsent";

const App = (props) => {
  const [cookieViewVisible, setCookieViewVisible] = useState(false);
  const [isConnected, setIsConnected] = useState(props.isConnected);
  const redirectionPath = isConnected ? "/operations/list" : "/login";

  useEffect(() => {
    setIsConnected(props.isConnected);
  }, [props.isConnected]);

  return (
    <div className="root">
      <Menu/>
      {isConnected &&
      <>
        <LogoutAlert />
        <Switch>
          <Route path='/operations/list' component={MyOperations}/>
          <Route path='/operations/edit/:id' component={EditOperation}/>
          <Route path='/operations/edit' component={AddOperation}/>
          <Route path='/favorites/list' component={MyFavorites}/>
          <Route path='/categories/list' component={MyCategories}/>
          <Route path='/categories/edit/:id' component={EditCategory}/>
          <Route path='/categories/view/:id' component={CategoryOverview}/>
          <Route path='/categories/edit' component={EditCategory}/>
          <Route path='/shops/list' component={MyShops}/>
          <Route path='/shops/edit/:id' component={EditShop}/>
          <Route path='/shops/view/:id' component={ShopOverview}/>
          <Route path='/shops/edit' component={EditShop}/>
          <Route path='/mentions' component={Mentions}/>
          <Redirect to={redirectionPath}/>
        </Switch>
      </>
      }
      {!isConnected &&
      <>
        <LogoutAlert />
        <Switch>
          <Route path='/login' component={Login}/>
          <Route path='/signup' component={SignUp}/>
          <Route path='/mentions' component={Mentions}/>
          <Redirect to={redirectionPath}/>
        </Switch>
      </>
      }
      <CookieConsent cookieViewVisible={cookieViewVisible} updateVisibilty={setCookieViewVisible}/>
      <footer>
        <span>© 2020 - MeiKorporation <span aria-label="cat foot print" role="img">🐾</span> </span><Link
        to={"/mentions"}>Mentions légales</Link><span> <a className={"cookie"} onClick={() => setCookieViewVisible(true)}>Cookies</a></span>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: !!state.session.accessToken,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
