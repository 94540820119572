import React, {Component} from 'react';
import * as R from 'ramda';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {getDecoratorsWithoutPagination} from "../services/decorator";
import {
  deleteOperation,
  getOperations,
  getOperationsWithoutPagination,
  getCount,
  getSum,
  updateOperation
} from "../services/operation";

class QuickStat extends Component {

  state = {
    filteredSum: 0,
    foreverSum: 0,
    foreverCount: 0,
  };

  componentDidMount() {
    this.props.getSum(this.props.predicate)
      .then((filteredSum) => {
        this.setState({filteredSum})
      });
    this.props.getSum({})
      .then((foreverSum) => {
        this.setState({foreverSum})
      });
    this.props.getCount({})
      .then((foreverCount) => {
        this.setState({foreverCount})
      });
  }

  componentDidUpdate(prevProps) {
    const {predicate, forceRefresh} = this.props;
    if (!R.equals(prevProps.predicate, predicate) || !R.equals(prevProps.forceRefresh, forceRefresh)) {
      this.props.getSum(predicate)
        .then((filteredSum) => {
          this.setState({filteredSum})
        });
      this.props.getSum({})
        .then((foreverSum) => {
          this.setState({foreverSum})
        });
      this.props.getCount({})
        .then((foreverCount) => {
          this.setState({foreverCount})
        });
    }
  }

  render() {
    if (R.isEmpty(this.props.operations)) {
      return null;
    }
    return R.isEmpty(this.props.predicate) ? (
      <Grid item>
        <Card style={{padding: 10, backgroundColor: '#aa1768', color: 'white'}}>
          {this.state.foreverSum} € / {this.state.foreverCount} opérations
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card style={{padding: 10, backgroundColor: '#aa1768', color: 'white'}}>
          {this.state.filteredSum} € / {this.props.filteredCount} opérations
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forceRefresh: state.tool.forceRefresh,
    operations: state.operation.list,
    predicate: state.operation.predicate,
    filteredCount: state.operation.meta.count,
    sum: R.reduce(R.add, 0, R.map(R.prop('amount'), state.operation.notPaginatedList)).toFixed(2),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteOperation,
  getDecoratorsWithoutPagination,
  getOperationsWithoutPagination,
  getOperations,
  getCount,
  getSum,
  updateOperation,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickStat));
