import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

const GA_PROPERTY = 'UA-35742747-8';
const GA_COOKIE_NAMES = ['__utma', '__utmb', '__utmc', '__utmz', '_ga', '_gat'];

const CookieConsent = ({updateVisibilty, cookieViewVisible}) => {
  const [bannerVisible, setBannerVisible] = useState(false);
  const [openInform, setOpenInform] = useState(cookieViewVisible);
  const [hasConsent, setHasConsent] = useState();

  const rejectCookie = () => {
    Cookies.set(`ga-disable-${GA_PROPERTY}`, true, { expires: 395 });
    window[`ga-disable-${GA_PROPERTY}`] = true;
    Cookies.set('hasConsent', false, { expires: 395 });
    GA_COOKIE_NAMES.forEach(cookieName => Cookies.remove(cookieName));
    setOpenInform(false);
    updateVisibilty(false);
    setBannerVisible(false);
  };

  const acceptCookie = () => {
    Cookies.set('hasConsent', true, { expires: 365 });
    Cookies.remove(`ga-disable-${GA_PROPERTY}`);
    startGoogleAnalytics();
    setOpenInform(false);
    updateVisibilty(false);
    setBannerVisible(false);
  };

  const startGoogleAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', GA_PROPERTY);
  };

  useEffect(() => {
    setOpenInform(cookieViewVisible);
  }, [cookieViewVisible]);

  useEffect(() => {
    const consentCookie = Cookies.get('hasConsent') ? Cookies.get('hasConsent') === "true" : null;
    setHasConsent(consentCookie);
    const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;

    if (doNotTrack === 'yes' || doNotTrack === '1' || consentCookie === false) {
      rejectCookie();
      return;
    }

    if (consentCookie === true) {
      startGoogleAnalytics();
      return;
    }

    if (doNotTrack === 'no' || doNotTrack === '0') {
      Cookies.set('hasConsent', true, { expires: 395 });
      startGoogleAnalytics();
      return;
    }

    setBannerVisible(true);
  }, []);

  if (openInform) {
    return (
      <div className="cookie-inform-and-ask">
        <div className="cookie-dialog">
          <h1>Les cookies Google Analytics</h1>
          <p>
            Ce site utilise des cookies de Google Analytics. Ces cookies nous aident à identifier l'intérêt pour le contenu
            et les fonctionnalités de l'application, ainsi qu'à repérer certains dysfonctionnements. Vos données de navigations sur ce site sont envoyées à Google Inc.
          </p>
          {hasConsent && <p>Vous avez accepté l'utilisation de ces cookies.</p>}
          <div>
            <button className="cookie-button" onClick={() => rejectCookie()}>{hasConsent ? "Révoquer" : "S'opposer"}</button>
            <button className="cookie-button accept" onClick={() => acceptCookie()}>{hasConsent ? "Confirmer" : "Accepter"}</button>
          </div>
        </div>
      </div>
    );
  }
  if (bannerVisible) {
    return (
      <div className={"cookie-full-screen"}>
        <div className="cookie-banner">
          <div className={"label"}>Ce site utilise Google Analytics. Nous aurions besoin de votre accord pour déposer un cookie à des fins de mesure d'audience (et rien d'autre!)</div>
          <div>
            <button className="cookie-button" onClick={() => setOpenInform(true)}>En savoir plus ou s'opposer</button>
            <button className="cookie-button accept" onClick={() => acceptCookie()}>Accepter le cookie</button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CookieConsent;
